@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono:wght@400;700&display=swap');

:root{
  --primary: #e6a639;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}

/* base styles & title */
body{
  font-family: "Cutive Mono";
  color: white;
  background-image: linear-gradient(white,black);
}
.App{
  max-width: 1300px;
  margin: 0 auto;
}
.title h1{
  color: var(--primary);
  font-size: 3.5rem;
  letter-spacing: 2px;
  font-weight: normal;
}
.title h2, .title p{
  text-align: center;
}
.title h2{
  margin-top: 60px;
  font-size: 2.6rem;
}

.intro {
  position:relative;
  text-align: center;
  width:100%;
  height:100%;
}

.intro img{
  display: inline-block; 
  position: relative; 
  width: 250px; 
  height: auto; 
  overflow: hidden; 
  border-radius: 50%; 
  margin-top: 60px;
}


/* upload form styles */
form{
  margin: 30px auto 10px;
  text-align: center;
}
label input{
  height: 0;
  width: 0;
  opacity: 0;
}
label{
  display: block;
  box-shadow: 0 0 30px rgba(0,0,0,0.4);
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
}
label:hover{
  background: var(--primary);
  color: white;
}
.output{
  height: 60px;
  font-size: 0.8rem;
}
.error{
  color: var(--error);
}

/* progress bar styles */
.progress-bar{
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

/* Dropdown Button */
.dropbtn {
  display: block;
  border: 1px solid var(--primary);
  margin: 10px auto;
  background-color: white;
  box-shadow: 0 0 30px rgba(0,0,0,0.4);
  padding: 16px;
  font-size: 16px;
  line-height: 30px;
  color: var(--primary);
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: var(--primary);}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: var(--primary);
  color: white;
}



/* image grid styles */
.img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
 
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
}
.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;

}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop img{
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0px auto;
  box-shadow: 10px 10px 10px 10px rgba(0,0,0,0.5);
  border: 0px solid black;
}

